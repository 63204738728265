import api from '../../assets/api.js';

const types = {
    SET_EXPORTS: 'SET_EXPORTS',
    SET_INCOME_ACCOUNT_REQUIRED: 'SET_INCOME_ACCOUNT_REQUIRED',
    SET_RECORD_COUNT_OF_INCOME_ACCOUNT_NULL: 'SET_RECORD_COUNT_OF_INCOME_ACCOUNT_NULL',
    SET_TEMPLATES: 'SET_TEMPLATES',
    SET_DEFAULT_COA: 'SET_DEFAULT_COA',
    SET_CHART_OF_ACCOUNTS: 'SET_CHART_OF_ACCOUNTS',
    SET_SELECTED_TEMPLATE: 'SET_SELECTED_TEMPLATE',
    SET_JOURNAL_EVENTS: 'SET_JOURNAL_EVENTS',
    SET_ACCOUNTING_SETUP: 'SET_ACCOUNTING_SETUP',
    SET_GENERAL_SETTINGS: 'SET_GENERAL_SETTINGS',
    SET_ACCOUNTING_SETTINGS: 'SET_ACCOUNTING_SETTINGS',
    SET_ACCOUNTING_ACTIVE_PROCESSES: 'SET_ACCOUNTING_ACTIVE_PROCESSES',
    GET_GL_ACCOUNTS: 'GET_GL_ACCOUNTS',
};

class Accounting {
    constructor(){
        this.namespaced = true;
        this.state = {
            exports: [],
            incomeAccountRequired: false,
            recordCountWithNullIncomeAccount: 0,
            templates: [],
            defaultCOA: [],
            chartOfAccounts: [],
            selectedTemplate: {},
            journalEvents: [],
            accountingSetup: false,
            accountingSettings: {},
            accountingActiveProcesses: [],
            glAccounts: [],
        };
        this.getters = {
            exports: state => state.exports,
            incomeAccountRequired: state => state.incomeAccountRequired,
            recordCountWithNullIncomeAccount: state => state.recordCountWithNullIncomeAccount,
            templates: state => state.templates,
            defaultCOA: state => state.defaultCOA,
            chartOfAccounts: state => state.chartOfAccounts,
            selectedTemplate: state => state.selectedTemplate,
            journalEvents: state => state.journalEvents,
            accountingSetup: state => state.accountingSetup,
            accountingSettings: state => state.accountingSettings,
            accountingActiveProcesses: state => state.accountingActiveProcesses,
            glAccounts: state => state.glAccounts,
        };
        this.mutations = {
            [types.SET_EXPORTS](state, payload) {
				state.exports = payload;
            },
            [types.SET_INCOME_ACCOUNT_REQUIRED](state, payload) {
				state.incomeAccountRequired = payload;
            },
            [types.SET_RECORD_COUNT_OF_INCOME_ACCOUNT_NULL](state, payload) {
				state.recordCountWithNullIncomeAccount = payload;
            },
            [types.SET_TEMPLATES](state, payload) {
                state.templates = payload;
            },
            [types.SET_DEFAULT_COA](state, payload) {
                state.defaultCOA = payload;
            },
            [types.SET_CHART_OF_ACCOUNTS](state, payload) {
                state.chartOfAccounts = payload;
            },
            [types.SET_SELECTED_TEMPLATE](state, payload) {
                state.selectedTemplate = payload;
            },
            [types.SET_JOURNAL_EVENTS](state, payload) {
                state.journalEvents = payload;
            },
            [types.SET_ACCOUNTING_SETUP](state, payload) {
                state.accountingSetup = payload;
            },
            [types.SET_ACCOUNTING_SETTINGS](state, payload) {
                state.accountingSettings = payload;
            },
            [types.SET_ACCOUNTING_ACTIVE_PROCESSES](state, payload) {
                state.accountingActiveProcesses = payload;
            },
            [types.GET_GL_ACCOUNTS](state, payload) {
                state.glAccounts = payload;
            },
        };
        this.actions = {
            async getExports({commit}, data = null){
                await api.get( this.$app, api.ACCOUNTING + 'exports/scheduled').then(payload => {
                    commit(types.SET_EXPORTS, payload.exports);
                });
            },

            async getAccountingType({commit, dispatch}, params) {
                let p = await api.get(this.$app, api.ACCOUNTING + 'settings');
                if ("toggleAccounting" in p && p.toggleAccounting == 1) {
                    commit(types.SET_INCOME_ACCOUNT_REQUIRED, true);
                }else{
                    commit(types.SET_INCOME_ACCOUNT_REQUIRED, false);
                }
            },

            async getIsAnyRecordWithoutIncomeAccount({commit}, params) {
                let p = await api.get(this.$app, api.ACCOUNTING + 'income-account-records', params);
                if (p.result.count > 0) {
                    commit(types.SET_RECORD_COUNT_OF_INCOME_ACCOUNT_NULL, p.result.count);
                }else {
                    commit(types.SET_RECORD_COUNT_OF_INCOME_ACCOUNT_NULL, 0);
                }
            },

            async getTemplates({commit}) {
                await api.get(this.$app, api.ACCOUNTING + 'templates').then(payload => {
                    if(Object.keys(payload).length) {
                        commit(types.SET_TEMPLATES, payload);
                    }
                });
            },

            async getChartOfAccounts({commit}) {
                await api.get(this.$app, api.ACCOUNTING + 'accounts').then(payload => {
                    if(Object.keys(payload).length) {
                        commit(types.SET_CHART_OF_ACCOUNTS, payload.accounts);
                    }
                });
            },

            async setChartOfAccounts({commit}, params) {
                commit(types.SET_CHART_OF_ACCOUNTS, params.accounts);
            },

            async getDefaultCOA({commit}, params) {
                const { id } = params;
                await api.get(this.$app, api.ACCOUNTING + "template/" + id + "?fetch_default_sub_types=true").then(payload => {
                    if(Object.keys(payload).length && payload?.accounting_template?.DefaultSubTypeAccounts) {
                        commit(types.SET_DEFAULT_COA, payload.accounting_template.DefaultSubTypeAccounts);
                    }
                });
            },

            async getJournalEvents({commit}, params) {
                const { id } = params;
                await api.get(this, api.ACCOUNTING + 'template/' + id + '?fetch_events=true').then(payload => {
                    commit(types.SET_JOURNAL_EVENTS, payload.accounting_template.GlEvents);
                })
            },

            async getAccountingTemplate({commit}, params) {
                const { id } = params;
                await api.get(this.$app, api.ACCOUNTING + "template/" + id).then(payload => {
                    commit(types.SET_SELECTED_TEMPLATE, payload.accounting_template);
                    if(payload?.accounting_template?.DefaultSubTypeAccounts) {
                        commit(types.SET_DEFAULT_COA, payload.accounting_template.DefaultSubTypeAccounts);
                    }
                    if(Object.keys(payload).length && payload?.accounting_template?.GlEvents) {
                        commit(types.SET_JOURNAL_EVENTS, payload.accounting_template.GlEvents)
                    }
                });
            },

            async saveTemplate({dispatch}, payload) {
                try {
                    let resp = {};
                    if(payload.id) {
                        resp = await api.put(this, api.ACCOUNTING + "template/" + payload.id, payload);
                    } else {
                        resp = await api.post(this, api.ACCOUNTING + "template", payload);
                    }
                    await dispatch('getAccountingTemplate', { id: resp.accounting_template_id });
                    await dispatch('getTemplates');
                } catch (error) {
                    throw error;
                }
            },

            async deleteTemplate({dispatch}, payload) {
                try {
                    await api.delete(this, api.ACCOUNTING + "template/" + payload.id);
                    await dispatch('getTemplates');
                } catch (error) {
                    throw error;
                }
            },

            async duplicateTemplate({dispatch}, payload) {
                try {
                    await api.post(this, api.ACCOUNTING + "template/" + payload.id + "/duplicate");
                    await dispatch('getTemplates');
                } catch (error) {
                    throw error;
                }
            },

            async setSelectedTemplate({commit}, payload) {
                commit(types.SET_SELECTED_TEMPLATE, payload);
            },

            async getAccountingSetup({commit, state}) {
                try {
                    if(!state.accountingSetup) {
                        let resp = await api.get(this, api.ACCOUNTING + "is_setup");
                        commit(types.SET_ACCOUNTING_SETUP, resp?.is_setup);
                    }
                } catch (error) {
                    throw error;
                }
            },

            async setAccountingSetup({commit, state}) {
                try {
                    if(!state.accountingSetup) {
                        await api.post(this, api.ACCOUNTING + "setup");
                        commit(types.SET_ACCOUNTING_SETUP, 1);
                    }
                } catch (error) {
                    throw error;
                }
            },

            async getAccountingSettings({commit}) {
                try {
                    let resp = await api.get(this, api.ACCOUNTING + "settings");
                    commit(types.SET_ACCOUNTING_SETTINGS, resp);
                } catch (error) {
                    throw error;
                }
            },

            async setAccountingSettings({dispatch}, payload) {
                const { generalSettings } = payload;
                try {
                    await api.post(this, api.ACCOUNTING + "settings", generalSettings);
                    dispatch('getAccountingSettings');
                } catch (error) {
                    throw error;
                }
            },

            async getAccountingActiveProcesses({commit}, payload) {
                const { glAccounts } = payload;
                var myArrayQry = glAccounts.map(function(el, idx) {
                    return 'gl_accounts[' + idx + ']=' + el.id;
                }).join('&');
                try {
                    let resp = await api.get(this, api.ACCOUNTING + "active-processes?" + myArrayQry);
                    commit(types.SET_ACCOUNTING_ACTIVE_PROCESSES, resp);
                } catch (error) {
                    throw error;
                }
            },

            async getGLAccountsAction({commit}, payload) {
                try {
                    let resp = await api.get(this, api.ACCOUNTING + 'accounts?all=true');
                    commit(types.GET_GL_ACCOUNTS, resp.accounts);
                } catch (error) {
                    throw error;
                }
            },
        };
    }
}

export default Accounting;
