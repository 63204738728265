export default {
  CHARM_GENERATE_TOKEN: "companies/:company_id/charm/token",
  CHARM_RESERVATIONS: "companies/:company_id/charm/reservations",
  CHARM_MISSED_CALLS: "companies/:company_id/charm/missed-calls",
  CHARM_VOICE_MAILS: "companies/:company_id/charm/voicemails",
  CHARM_CALL_HOLD_UNHOLD: "companies/:company_id/charm/hold",
  CHARM_RESERVATIONS_READ:"companies/:company_id/charm/reservation",
  CHARM_MISSED_CALLS_READ:"companies/:company_id/charm/missed-call",
  CHARM_VOICE_MAILS_READ: "companies/:company_id/charm/voicemail",
  CHARM_CALL_ACCEPT: "companies/:company_id/charm/phonecall",
  CHARM_SUBSCRIBE_PROPERTIES: 'companies/:company_id/charm/subscriptions',
  CHARM_CALL_SEND_TO_VOICEMAIL: 'companies/:company_id/charm/voicemails/send',
  CHARM_OUTGOING_CALL: 'companies/:company_id/charm/outgoing',
  CHARM_AVAILABLE_AGENT: 'companies/:company_id/charm/agent/available',
  CHARM_RESERVATION_BY_ID: "companies/:company_id/charm/reservation/",
  CHARM_MISSED_CALL_BY_ID: "companies/:company_id/charm/missed-call/",
  CHARM_VOICEMAIL_BY_ID: "companies/:company_id/charm/voicemail/",
  CHARM_CONTACT:"companies/:company_id/charm/contact/",
  CHARM_END_CALL: 'companies/:company_id/charm/end',
  CHARM_VOICEMAIL:'companies/:company_id/charm/voicemail-settings',
  START_CARD_COLLECTION:'companies/:company_id/charm/payment-session',
  COLLECT_CARD_DETAILS:'companies/:company_id/charm/payment-session/card-details',
  UPDATE_CARD_COLLECTION:'companies/:company_id/charm/payment-session',
};
