import api from '../../assets/api.js';

const types = {
    /**
     * RATE MANAGEMENT
     */
    SET_RATE_PLANS: 'SET_RATE_PLANS',
    SET_COMPANY_RATE_PLAN_CONF: 'SET_COMPANY_RATE_PLAN_CONF',
    SET_CURRENT_PROPERTY_RATE_SETTINGS: 'SET_CURRENT_PROPERTY_RATE_SETTINGS',

    /**
     * RENT MANAGEMENT
     */
    SET_RENT_PLANS: 'SET_RENT_PLANS',
    SET_RENT_AUTOMATION: 'SET_RENT_AUTOMATION',
    SET_COMPANY_RENT_SETTINGS: 'SET_COMPANY_RENT_SETTINGS',
    SET_CURRENT_PROPERTY_RENT_SETTINGS: 'SET_CURRENT_PROPERTY_RENT_SETTINGS',

    /**
     * PROMOTION MANAGEMENT
     */
    SET_PRODUCTS: 'SET_PRODUCTS',
    SET_PROMOTION_PLANS: 'SET_PROMOTION_PLANS',
    SET_ACTIVE_PROMOTIONS: 'SET_ACTIVE_PROMOTIONS',
    SET_PROPERTY_DISCOUNTS: 'SET_PROPERTY_DISCOUNTS',
    SET_CURRENT_PROPERTY_PROMOTION_SETTINGS: 'SET_CURRENT_PROPERTY_PROMOTION_SETTINGS',
    SET_COMPANY_PROMOTION_SETTINGS: 'SET_COMPANY_PROMOTION_SETTINGS',
    SET_PROMOTION_AUTOMATION: 'SET_PROMOTION_AUTOMATION',

    /**
    * OTHERS
    */
    SET_SPACE_DATA: 'SET_SPACE_DATA',
    SET_PROPERTY: 'SET_PROPERTY',
}

class RevenueManagement {
    constructor() {
        this.namespaced = true
        this.state = {
            rate: {
                plans: [],
                companyRatePlanConf: null,
                currentPropertyRatePlanConf: null,
                spaceData: {}
            },
            rent: {
                plans: [],
                automation: false,
                companyRentSettings: null,
                currentPropertyRentSettings: null,
            },
            promotion: {
                products: [],
                plans: [],
                automation: false,
                activePromotions: null,
                propertyDiscounts: null,
                currentPropertyPromotionSettings: null,
                companyPromotionSettings: null,
            },
            selectedProperty: null
        }
        this.getters = {
            /**
             * RATE MANAGEMENT
             */
            defaultRatePlan: state => state.rate.plans.find(plan => plan.isDefault) ?? null,
            companyRatePlanConf: state => state.rate.companyRatePlanConf ?? null,
            getRatePlanById: state => rate_id => state.rate.plans.find(plan => plan.id == rate_id) ?? null,
            getPropertyRatePlanConf: state => state.rate?.currentPropertyRatePlanConf ?? null,
            isRateManagementEnabled: state => state.rate?.currentPropertyRatePlanConf?.active ?? true,
            getDefaultGroupingProfile: state => state.rate?.currentPropertyRatePlanConf?.space_group_profile?.name ?? '',
            hasInternalRateEngine: state => state.rate?.currentPropertyRatePlanConf ? state.rate?.currentPropertyRatePlanConf.rate_engine == 'hummingbird' : true,
            rateEngine: state => state.rate?.currentPropertyRatePlanConf?.rate_engine,



            /**
             * RENT MANAGEMENT
             */
            getRentPlanById: state => rent_id => state.rent.plans.find(plan => plan.id == rent_id) ?? null,
            /**
             * returns all rent plan names on create new rent plan
             * returns all rent plan names except the one which is editing while editing a rent plan
             */
            getRentPlanNames: state => rent => state.rent.plans.map(plan => plan.name).filter(name => {
                return rent?.id ? name !== rent?.name : name
            })
                ?? [],
            companyRentSettings: state => state.rent?.companyRentSettings ?? null,
            getPropertyRentSettings: state => state.rent?.currentPropertyRentSettings ?? null,
            hasInternalRentEngine: state => state.rent?.currentPropertyRentSettings ? state.rent.currentPropertyRentSettings.rent_engine == 'hummingbird' : true,
            rentEngine: state => state.rent?.currentPropertyRentSettings?.rent_engine,



            /**
             * PROMOTION MANAGEMENT
             */
            getProducts: state => state.promotion.products ?? [],
            promotionPlans: state =>  state.promotion.plans ?? [],
            getActivePromotions: state => state.promotion?.activePromotions ?? [],
            getPropertyDiscounts: state => state.promotion?.propertyDiscounts ?? [],
            getPropertyPromotionSettings: state => state.promotion.currentPropertyPromotionSettings ?? {},
            companyPromotionSettings: state => state.promotion.companyPromotionSettings ?? {},
            defaultPromotionPlan: state => state.promotion.plans.find(plan => plan.isDefault) ?? null,
            promotionGroupingProfile: state => state.promotion.currentPropertyPromotionSettings?.space_group_profile ?? null,



            /**
             * OTHERS
             */
            getSpaceData: state => state.rate.spaceData ?? [],
            /**
             * returns the property from main selector or from internal selector
             * main selector takes priority
             */
            getSelectedProperty: (state, getters, rootState, rootGetters) => {
                let properties = rootGetters['propertiesStore/filtered']
                if (properties?.length === 1) {
                    return properties[0]?.id;
                }
                return state.selectedProperty
            },
        }
        this.actions = {
            async fetchRatePlans({ commit, dispatch }, payload) {
                let plans = await api.get(this, api.COMPANY_RATE_MANAGEMENT + "plans").catch(err => [])
                await dispatch('fetchCompanyRatePlanConfiguration')
                commit(types.SET_RATE_PLANS, plans)
            },
            async fetchCompanyRatePlanConfiguration({ commit, dispatch }, payload) {
                let conf = await api.get(this, api.COMPANY_RATE_MANAGEMENT).catch(err => [])
                commit(types.SET_COMPANY_RATE_PLAN_CONF, conf)
            },
            /**
             * Fetch Rate management configurations for the selected property.
             */
            async fetchCurrentPropertyRatePlanConfiguration({ commit, getters }, payload) {
                let conf = await api.get(
                    this,
                    api.getPropertyRateManagementUrl(getters.getSelectedProperty)
                ).catch(err => [])
                commit(types.SET_CURRENT_PROPERTY_RATE_SETTINGS, conf)
            },
            setProperty({ commit }, property) {
                commit(types.SET_PROPERTY, property);
            },

            async fetchRentPlans({ commit, dispatch }, payload) {
                let data = await api.get(this, api.COMPANY_RENT_MANAGEMENT + "plans").catch(err => [])
                commit(types.SET_RENT_PLANS, data?.rent_management_plans?.sort((a, b) => a.name.localeCompare(b.name)) ?? [])
                commit(types.SET_RENT_AUTOMATION, data?.automation_enabled)
            },


            async fetchCompanyRentConfiguration({ commit, dispatch }, payload) {
                let conf = await api.get(this, api.COMPANY_RENT_MANAGEMENT).catch(err => [])
                commit(types.SET_COMPANY_RENT_SETTINGS, conf)
            },
            /**
             * Fetch Rate management configurations for the selected property.
             */
            async fetchCurrentPropertyRentConfiguration({ commit, getters }, property) {
                let conf = await api.get(
                    this,
                    api.getPropertyRentManagementUrl(property ? property : getters.getSelectedProperty)
                ).catch(err => [])
                commit(types.SET_CURRENT_PROPERTY_RENT_SETTINGS, conf.property_rent_management_settings)
            },
            async fetchProducts({commit, getters}, liveData = false) {
                if(getters.getProducts.length && !liveData) {
                    return getters.getProducts
                } else {
                    let products = await api.get(this, api.PRODUCTS).then((r) => {
                        return r.products
                    }).catch(err => []);
                    commit(types.SET_PRODUCTS, products)
                    return products
                }
            },
            /**
             * Fetch Promotion management configurations..
             */

            async fetchPromotionPlans({commit, dispatch }, payload) {
                let response = await api
                    .get(this, api.PROMOTIONS + 'plans')
                    .catch((err) => console.log(err));
                await dispatch('fetchCompanyPromotionSettings')
                commit(types.SET_PROMOTION_PLANS, response.promotion_plans?.sort((a, b) => 
                    a?.name.toLowerCase().localeCompare(b?.name.toLowerCase())
                ) ?? [])
                commit(types.SET_PROMOTION_AUTOMATION, response?.automation_enabled)
            },

            async fetchCurrentPropertyPromotionConfiguration({ commit, getters }, property) {
                let conf = await api.get(
                    this,
                    api.getPropertyPromoManagementUrl(property ? property : getters.getSelectedProperty)
                ).catch(err => [])
                console.log("conf --> ",conf)
                commit(types.SET_CURRENT_PROPERTY_PROMOTION_SETTINGS, conf)
            },

            async fetchActivePromotions({commit, dispatch }, payload = {}) {
                const { contact_id = null, property_id = null, unit_id = null, channel_type = null, rental_flow = false } = payload;
            
                const queryParams = new URLSearchParams();
                if (contact_id) queryParams.append('contact_id', contact_id);
                if (property_id) queryParams.append('property_id', property_id);
                if (unit_id) queryParams.append('unit_id', unit_id);
                if (channel_type) queryParams.append('channel_type', channel_type);
                if (rental_flow) queryParams.append('rental_flow', rental_flow);
            
                let promotions = [];
                try {
                    const response = await api.get(this, `${api.PROMOTIONS}?${queryParams.toString()}`);
                    promotions = response.promotions.filter(promo => promo?.enable);
                } catch (err) {
                    console.error('Failed to fetch promotions:', err);
                }
            
                commit(types.SET_ACTIVE_PROMOTIONS, promotions);
            },

            async fetchPropertyDiscounts({commit, dispatch }, payload = {}) {
                const { property_id = null } = payload;
                let discounts = []
                if(property_id){
                    await api
                    .get(this, api.getPropertyDiscountManagementUrl(property_id ? property_id : getters.getSelectedProperty))
                    .then((response) => {
                        discounts = response?.[0]?.discounts;
                    })
                .catch((err) => console.log(err));
                }

                commit(types.SET_PROPERTY_DISCOUNTS, discounts)
            },
            
            async fetchCompanyPromotionSettings({ commit, dispatch }, payload) {
                let conf = await api.get(this, api.PROMOTION_MANAGEMENT).catch(err => [])
                commit(types.SET_COMPANY_PROMOTION_SETTINGS, conf)
            },
        }
        this.mutations = {

            /**
             * RATE MANAGEMENT
             */
            [types.SET_RATE_PLANS](state, ratePlans = null) {
                let plans = ratePlans ?? state.rate.plans
                if (Array.isArray(plans)) {
                    let planConf = state.rate.companyRatePlanConf
                    plans.map((rate) => {
                        rate["isDefault"] = false;
                        if (planConf && rate.id == planConf.default_rate_plan_id) {
                            rate["isDefault"] = true
                        }
                    });
                    state.rate.plans = plans
                }
                else {
                    plans["isDefault"] = false;
                    state.rate.plans.push(plans)
                }
            },
            [types.SET_COMPANY_RATE_PLAN_CONF](state, conf) {
                state.rate.companyRatePlanConf = conf
            },
            [types.SET_CURRENT_PROPERTY_RATE_SETTINGS](state, conf) {
                state.rate.currentPropertyRatePlanConf = conf;
            },



            /**
             * RENT MANAGEMENT
             */
            [types.SET_RENT_PLANS](state, rentPlans = null) {
                let plans = rentPlans ?? state.rent.plans
                if (Array.isArray(plans)) state.rent.plans = plans
                else state.rent.plans.push(plans)
            },
            [types.SET_RENT_AUTOMATION](state, automation = false) {
                state.rent.automation = automation
            },
            [types.SET_COMPANY_RENT_SETTINGS](state, conf) {
                state.rent.companyRentSettings = conf
            },
            [types.SET_CURRENT_PROPERTY_RENT_SETTINGS](state, conf) {
                state.rent.currentPropertyRentSettings = conf;
            },



            /**
             * PROMOTION MANAGEMENT
             */
            [types.SET_PRODUCTS](state, products = []) {
                state.promotion.products = products
            },
            [types.SET_PROMOTION_PLANS](state, promoPlans = null) {
                let plans = promoPlans ?? state.promotion.plans
                // let planConf = state.promotion.companyPromotionSettings
                // plans.map((plan) => {
                //     plan["isDefault"] = false;
                //     if (planConf && plan.id == planConf.promotion_management_plan_id) {
                //         plan["isDefault"] = true
                //     }
                // });
                state.promotion.plans = plans
            },
            [types.SET_PROMOTION_AUTOMATION](state, automation = false) {
                state.promotion.automation = automation
            },

            [types.SET_ACTIVE_PROMOTIONS](state, promotions = []) {
                state.promotion.activePromotions = promotions
            },
            [types.SET_PROPERTY_DISCOUNTS](state, discounts = []) {
                state.promotion.propertyDiscounts = discounts
            },
            [types.SET_CURRENT_PROPERTY_PROMOTION_SETTINGS](state, conf) {
                state.promotion.currentPropertyPromotionSettings = conf;
            },
            [types.SET_COMPANY_PROMOTION_SETTINGS](state, conf) {
                state.promotion.companyPromotionSettings = conf
            },

            /**
             * OTHERS
             */
            [types.SET_SPACE_DATA](state, spaceData = null) {
                state.rate.spaceData = spaceData
            },
            [types.SET_PROPERTY](state, property) {
                state.selectedProperty = property;
                state.rate.currentPropertyRatePlanConf = null
                state.rent.currentPropertyRentSettings = null
            },
        };
    }
}

export default RevenueManagement;
